import {
  AlertIcon,
  Box,
  InfoPanel,
  variants,
  Text,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import messages from '@/messages/payment'

const AppleGenericErrorPanel = () => {
  const intl = useIntl()
  return (
    <Box pb={3}>
      <InfoPanel icon={AlertIcon}>
        <Text variant={variants.text.bodyBold}>
          {intl.formatMessage(messages.smthWentWrong)}
        </Text>
        <Text variant={variants.text.body}>
          {intl.formatMessage(messages.smthWentWrongText)}
        </Text>
      </InfoPanel>
    </Box>
  )
}

export default AppleGenericErrorPanel
