import PropTypes from 'prop-types'
import { Box, Column, Grid } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { TRACKING_PROPERTY } from 'scripts/tracking'
import LoginForm from '@/components/auth/forms/login'
import ActionLink from '@/components/auth/login-actions/action-link'
import auth from '@/messages/auth'
import { useRALogin } from '@/components/auth/useRALoginWrapper'
import OauthErrorWrapper from '@/components/auth/oauth-error-wrapper'

const sourceFromReturnUrl = (returnUrl = '') => {
  if (returnUrl.includes('/pro/event')) {
    return returnUrl.includes('update')
      ? TRACKING_PROPERTY.loginSource.updateEvent
      : TRACKING_PROPERTY.loginSource.submitEvent
  }

  if (returnUrl.includes('pitch')) {
    return TRACKING_PROPERTY.loginSource.pitch
  }

  if (returnUrl.includes('pro')) {
    return TRACKING_PROPERTY.loginSource.raPro
  }

  return TRACKING_PROPERTY.loginSource.login
}

const LoginPageContent = ({ returnUrl = '/', reloadUrl }) => {
  const intl = useIntl()
  const source = sourceFromReturnUrl(returnUrl)
  const handleLogin = useRALogin({
    returnUrl,
    reloadUrl,
    onSuccess: undefined,
    source,
  })

  return (
    <Grid
      gridTemplateRows="1fr"
      mCols={12}
      sCols={1}
      gridTemplateAreas={{ s: sGTA, m: mGTA, l: lGTA }}
    >
      <Column area={Areas.Login}>
        <OauthErrorWrapper>
          <LoginForm
            onSubmit={handleLogin}
            source={source}
            oauthRedirectUri={reloadUrl || returnUrl}
          />
        </OauthErrorWrapper>
      </Column>
      <Column area={Areas.Register}>
        <Box>
          <ActionLink
            title={intl.formatMessage(auth.newToRA)}
            buttonText={intl.formatMessage(auth.register)}
            buttonUrl="/register"
          />
        </Box>
      </Column>
    </Grid>
  )
}

const lGTA = `
  "login login login login login . . . . register register register"
  `

const mGTA = `
  "login login login login login . . . . register register register"
  `

const sGTA = `
  "login"
  "register"
  `

const Areas = {
  Login: 'login',
  Register: 'register',
}

LoginPageContent.propTypes = {
  returnUrl: PropTypes.string,
  reloadUrl: PropTypes.string,
}

export default LoginPageContent
