import { SubSection, Text, variants } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import auth from '@/messages/auth'
import Header from '@/components/generic/header'
import Padded from '@/components/generic/padded'
import messages from '@/messages/seo'
import Seo from '@/components/generic/seo/Seo'
import useSeo from '@/hooks/useSeo'
import LoginPageContent from './LoginPageContent'

const LoginPage: React.FC = () => {
  const intl = useIntl()
  const { query } = useRouter()
  const seoData = useSeo({
    titleMessage: {
      message: messages.loginTitle,
    },
    descriptionMessage: {
      message: messages.loginDescription,
    },
  })

  return (
    <>
      <Seo {...seoData} />
      <Header text={intl.formatMessage(auth.signin)} />
      {query?.notAuthenticated && (
        <Padded>
          <SubSection>
            <Text variant={variants.text.heading.l}>
              {intl.formatMessage(auth.needToBeLoggedIn)}
            </Text>
          </SubSection>
        </Padded>
      )}
      <Padded>
        <SubSection>
          <LoginPageContent
            returnUrl={query.returnUrl as string}
            reloadUrl={query.reloadUrl as string}
          />
        </SubSection>
      </Padded>
    </>
  )
}

export default LoginPage
