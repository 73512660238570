import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { Box, Text, colors, variants } from '@resident-advisor/design-system'
import OauthErrors from 'lib/oauth'
import auth from '@/messages/auth'
import AppleNewUserErrorPanel from './AppleNewUserErrorPanel'
import AppleGenericErrorPanel from './AppleGenericErrorPanel'

const OauthErrorWrapper = ({
  errorUrl = '/login',
  children,
}: PropsWithChildren<OauthErrorWrapperProps>) => {
  const intl = useIntl()
  const router = useRouter()
  const [error] = useState(router?.query?.error || null)

  useEffect(() => {
    if (router?.query?.error) {
      router.push(errorUrl, undefined, { shallow: true })
    }
  }, [router, errorUrl])

  return !error ? (
    <> {children}</>
  ) : (
    <>
      {error === OauthErrors.NewUser && <AppleNewUserErrorPanel />}
      {error === OauthErrors.GenericError && <AppleGenericErrorPanel />}
      {children}
      <Box pt={3}>
        <Text variant={variants.text.small} color={colors.coral}>
          {error === OauthErrors.NewUser
            ? intl.formatMessage(auth.oauthUserIsNewErrorText)
            : intl.formatMessage(auth.oauthGenericErrorText)}
        </Text>
      </Box>
    </>
  )
}

type OauthErrorWrapperProps = {
  errorUrl?: string
}

export default OauthErrorWrapper
