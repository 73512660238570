import React, { useEffect, useState } from 'react'
import {
  Alignment,
  Text,
  variants,
  InfoPanel,
  AlertIcon,
  Icon,
  colors,
  CloseIcon,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import auth from '@/messages/auth'
import Link from '@/components/generic/link'

const EMAIL_VERIFIED_STATUS_CODE = 'EmailNotVerified'

const UnverifiedEmailBox: React.FC = () => {
  const intl = useIntl()
  const { status } = useFormikContext()
  const [isEmailNotVerifiedVisible, setIsEmailNotVerifiedVisible] =
    useState(true)

  const showError =
    status?.statusCode === EMAIL_VERIFIED_STATUS_CODE &&
    isEmailNotVerifiedVisible

  useEffect(() => {
    setIsEmailNotVerifiedVisible(
      status?.statusCode === EMAIL_VERIFIED_STATUS_CODE
    )
  }, [status])

  if (!showError) {
    return null
  }

  return (
    <Alignment mb={{ s: 4 }} mt={{ s: 3 }}>
      <InfoPanel
        CloseButtonComponent={
          <Icon
            role="button"
            iconSize={20}
            color={colors.grey[80]}
            Component={CloseIcon}
            onClick={() => setIsEmailNotVerifiedVisible(false)}
          />
        }
        icon={AlertIcon}
      >
        <Alignment>
          <Text variant={variants.text.heading.s}>
            {intl.formatMessage(auth.verifyEmailTitle)}
          </Text>
        </Alignment>
        <Alignment>
          <Text variant={variants.text.body.medium}>
            {intl.formatMessage(auth.verifyEmailText)}
          </Text>
        </Alignment>
        <Alignment pt={3}>
          <Link href="/resend-verification" underline>
            {intl.formatMessage(auth.resendVerificationEmail)}
          </Link>
        </Alignment>
      </InfoPanel>
    </Alignment>
  )
}

export default UnverifiedEmailBox
