import {
  Alignment,
  Box,
  Button,
  Text,
  TextInput,
  variants,
} from '@resident-advisor/design-system'
import { Formik, FormikHelpers } from 'formik'
import { useIntl } from 'react-intl'
import { TRACKING_PROPERTY } from 'scripts/tracking'
import { useRouter } from 'next/router'
import testIds from '@/enums/testIds'
import Link from '@/components/generic/link'
import auth from '@/messages/auth'
import { Form, Field, FormErrors, PasswordInput } from '@/components/forms'
import { LoginValues, statusCodeMessages } from '@/components/auth/useRALoginWrapper'
import buttonTrackingIds from '@/tracking/button-tracking-ids'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import AppleSignIn from '@/components/auth/apple-sign-in/AppleSignIn'
import FORM_TYPE from '@/enums/form-type'
import useValidationSchema from './useValidationSchema'
import UnverifiedEmailBox from './UnverifiedEmailBox'

const FORGOT_PASSWORD_LINK = '/forgot-password'
const EMAIL_VERIFIED_STATUS_CODE = 'EmailNotVerified'

const LoginForm = ({
  onSubmit,
  title,
  forgotPasswordLink = FORGOT_PASSWORD_LINK,
  displayMode = LoginFormDisplayMode.Default,
  appleButtonColor = 'black',
  source,
  oauthRedirectUri,
}: LoginFormProps) => {
  const intl = useIntl()
  const {
    query: { usernameOrEmail },
  } = useRouter()

  const validationSchema = useValidationSchema()
  const savedEventsLoginBlurb =
    source === TRACKING_PROPERTY.loginSource.savedEvents
      ? intl.formatMessage(auth.savedEventsLoginBlurb)
      : null

  const enableAppleSignIn = displayMode === LoginFormDisplayMode.Default

  return (
    <Formik
      initialValues={{
        usernameOrEmail: usernameOrEmail || '',
        password: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, status, values }) => {
        return (
          <Form type={FORM_TYPE.formik}>
            <UnverifiedEmailBox />
            {title && <Text variant={variants.text.heading.l}>{title}</Text>}
            {savedEventsLoginBlurb && (
              <Alignment pt={3}>
                <Text variant={variants.text.body.large}>
                  {savedEventsLoginBlurb}
                </Text>
              </Alignment>
            )}
            <Box pt={3}>
              <Field
                data-test-id={testIds.loginUsername}
                data-pw-test-id={PLAYWRIGHT_TEST_IDS.loginUsername}
                Component={TextInput}
                label={intl.formatMessage(auth.usernameOrEmail)}
                name="usernameOrEmail"
                indicateRequired
                type="email"
                textVariant="body"
              />
            </Box>
            <Box pt={3}>
              <PasswordInput
                data-test-id={testIds.loginPassword}
                data-pw-test-id={PLAYWRIGHT_TEST_IDS.loginPassword}
                label={intl.formatMessage(auth.password)}
                indicateRequired
                textVariant="body"
              />
            </Box>
            <Alignment
              flexDirection={{
                s: 'column',
                m: enableAppleSignIn ? 'column' : 'row',
              }}
              justifyItems="stretch"
              pb={{ m: enableAppleSignIn ? undefined : 3 }}
              pt={3}
            >
              <Button
                data-test-id={testIds.loginSubmit}
                data-pw-test-id={PLAYWRIGHT_TEST_IDS.loginSubmit}
                type="submit"
                isLoading={isSubmitting}
                mr={{ s: undefined, m: enableAppleSignIn ? 1 : 3 }}
                mb={{ s: 3, m: enableAppleSignIn ? 3 : undefined }}
                data-button-tracking-id={buttonTrackingIds.loginFormSubmit}
              >
                {intl.formatMessage(auth.signin)}
              </Button>
              <Alignment pt={{ m: enableAppleSignIn ? 0 : 2 }}>
                <Link
                  href={buildForgotPasswordLink(forgotPasswordLink, values)}
                >
                  <Text color="secondary" variant={variants.text.tag}>
                    {intl.formatMessage(auth.forgotYourPassword)}
                  </Text>
                </Link>
              </Alignment>
            </Alignment>

            {enableAppleSignIn && (
              <Alignment
                flexDirection="column"
                justifyItems="stretch"
                pt={3}
                pb={3}
              >
                <AppleSignIn
                  color={appleButtonColor}
                  source={source}
                  redirectUri={oauthRedirectUri}
                  buttonText={intl.formatMessage(auth.signInWithAppleText)}
                />
              </Alignment>
            )}
            {status?.statusCode !== EMAIL_VERIFIED_STATUS_CODE && (
              <FormErrors
                statusCodeMessages={statusCodeMessages(intl, false)}
                pt={2}
                statusCode={status?.statusCode}
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

const buildForgotPasswordLink = (forgotPasswordLink, values) => {
  if (values?.usernameOrEmail) {
    return `${forgotPasswordLink}?account_identifier=${encodeURIComponent(
      values.usernameOrEmail
    )}`
  }

  return forgotPasswordLink
}

enum LoginFormDisplayMode {
  Default = 'Default',
  Payment = 'Payment',
}

type LoginFormProps = {
  onSubmit: (values: LoginValues, actions: FormikHelpers<LoginValues>) => void
  title?: string
  forgotPasswordLink?: string
  displayMode?: LoginFormDisplayMode
  appleButtonColor?: string
  source: string
  oauthRedirectUri: string
}

export { LoginFormDisplayMode }
export default LoginForm
