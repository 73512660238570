import getConfig from 'next/config'
import { colors, Icon, AppleIcon } from '@resident-advisor/design-system'
import base64url from 'base64url'
import styled from 'styled-components'
import Tracking, { TRACKING_EVENT, TRACKING_PROPERTY } from 'scripts/tracking'
import LinkButton from '@/components/generic/link-button'
import testIds from '@/enums/testIds'

const {
  publicRuntimeConfig: { APPLE_SIGNIN },
} = getConfig()

const AppleSignInButton = styled(LinkButton)`
  background-color: ${({ color }) =>
    color === 'black' ? colors.black : colors.white};
  border-style: none;
  color: ${({ color }) => (color === 'black' ? colors.white : colors.black)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const AppleSignIn = ({
  redirectUri,
  signUpUrl,
  fallbackUrl,
  source,
  color = 'black',
  buttonText,
}: AppleSignInProps) => {
  // Generates the URL for Apple Sign In
  // see https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms#3332113
  const generateSignInURL = () => {
    const { CLIENT_ID, REDIRECT_URI, SCOPE } = APPLE_SIGNIN
    const params = new URLSearchParams({
      response_type: 'code id_token',
      response_mode: 'form_post',
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      scope: SCOPE,
      state: base64url.encode(
        JSON.stringify({
          redirectUri,
          signUpUrl,
          fallbackUrl,
          source,
        })
      ),
    })

    const queryString = params.toString()

    const url = new URL(
      `https://appleid.apple.com/auth/authorize?${queryString}`
    )
    return url.toString()
  }

  return (
    <AppleSignInButton
      data-testid={testIds.appleSignInButton}
      color={color}
      href={generateSignInURL()}
      onClick={() => {
        Tracking.trackMixpanel(TRACKING_EVENT.loginAttempt, {
          'Login Method': TRACKING_PROPERTY.loginMethod.apple,
          'Login Source': source,
        })
      }}
    >
      <Icon Component={AppleIcon} />
      {buttonText}
    </AppleSignInButton>
  )
}

type AppleSignInProps = {
  redirectUri?: string
  signUpUrl?: string
  fallbackUrl?: string
  color?: string
  source?: string
  buttonText?: string
}

export default AppleSignIn
