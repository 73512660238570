import regex from 'lib/regex'
import { IntlShape, useIntl } from 'react-intl'
import * as Yup from 'yup'
import messages from '@/messages/errors'

const useValidationSchema = () => {
  const intl = useIntl()

  return Yup.object().shape({
    usernameOrEmail: getUsernameOrEmailValidation(intl),
    password: Yup.string().required(intl.formatMessage(messages.required)),
  })
}

const getUsernameOrEmailValidation = (intl: IntlShape) =>
  Yup.string()
    .required(intl.formatMessage(messages.required))
    .test({
      name: 'Email',
      message: intl.formatMessage(messages.invalidEmail),
      test: (value) =>
        !value || (value && !value.includes('@')) || regex.email.test(value),
    })

export { getUsernameOrEmailValidation }
export default useValidationSchema
