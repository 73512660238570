import PropTypes from 'prop-types'
import { Text, Alignment, variants } from '@resident-advisor/design-system'
import LinkButton from '@/components/generic/link-button'

const ActionLink = ({ title, buttonText, buttonUrl }) => {
  return (
    <Alignment flexDirection="column" alignItems="start">
      {title && (
        <Text variant={variants.text.heading.l} mb={{ s: 2, m: 4 }}>
          {title}
        </Text>
      )}
      {buttonText && (
        <LinkButton href={buttonUrl} variant={variants.button.secondary}>
          {buttonText}
        </LinkButton>
      )}
    </Alignment>
  )
}

ActionLink.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
}

export default ActionLink
