import { GetServerSideProps } from 'next'
import CustomGetServerSidePropsContext from '@/interfaces/CustomGetServerSidePropsContext'

const getServerSideProps: GetServerSideProps = async ({
  req,
  query,
}: CustomGetServerSidePropsContext) => {
  /**
   * Redirect automatically on page load (or client-side page transition)
   * if the user is in a redirect flow but is already logged in
   */
  if (req.user && query.returnUrl) {
    return {
      redirect: {
        destination: query.returnUrl as string,
        permanent: false,
      },
    }
  }
  return { props: {} }
}

export default getServerSideProps
