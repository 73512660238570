import {
  InfoPanel,
  StopIcon,
  variants,
  Text,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import Link from '@/components/generic/link'
import auth from '@/messages/auth'

const AppleNewUserErrorPanel = () => {
  const intl = useIntl()
  return (
    <InfoPanel
      icon={StopIcon}
      renderCallToAction={() => (
        <Link href="/register" underline>
          {intl.formatMessage(auth.register)}
        </Link>
      )}
    >
      <Text variant={variants.text.bodyBold}>
        {intl.formatMessage(auth.oauthUserIsNewTitle)}
      </Text>
      <Text variant={variants.text.body}>
        {intl.formatMessage(auth.oauthUserIsNewSubtitle)}
      </Text>
    </InfoPanel>
  )
}

export default AppleNewUserErrorPanel
